import { makeStyles } from '@material-ui/core/styles';
import {
  green,
  grayBG,
  red,
  cyan,
  textNoWrap,
  textColor,
} from '../../constants';

export default makeStyles({
  TextField: {
    padding: 5,
    backgroundColor: grayBG,
    border: '2px solid transparent',
    '& :-webkit-autofill': {
      boxShadow: `0 0 0px 1000px ${grayBG} inset`,
    },
    '&.isError input': {
      color: 'transparent',
    },
    '&.isError': {
      borderColor: red,
    },
  },
  passwordField: {
    padding: 5,
  },
  adornment: {
    marginRight: '5px',
    width: '20px',
    height: '20px',
  },
  formControl: {
    margin: '5px 0',
    width: '100%',
  },
  button: {
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: green,
    color: 'white',
    lineHeight: '30px',
    marginTop: '5px',
  },
  mainContainer: {
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  logoContainer: {
    textAlign: 'center',
  },
  logoStyle: {
    width: '100%',
    marginBottom: '20px',
    maxWidth: '200px',
  },
  headingStyle: {
    margin: '5px 0',
    textAlign: 'center',
    ...textNoWrap,
  },
  logoAnimated: {
    width: 'min(400px, 80%)',
    maxWidth: '100%',
  },
  videoContainer: {
    marginTop: '50px',
    overflow: 'hidden',
    textAlign: 'center',
  },
  arrowBack: {
    padding: 20,
    color: textColor,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  resetPasswordContainer: {
    padding: 15,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
  },
  error: {
    position: 'absolute',
    color: red,
  },
  siteLinkWrapper: {
    textAlign: 'center',
  },
  siteLink: {
    textDecoration: 'none',
    color: cyan,
  },
  errorMessage: {
    position: 'absolute',
    top: '17%',
    fontSize: 16,
    left: 35,
    color: red,
    pointerEvents: 'none',
    ...textNoWrap,
  },
  passwordRequirementsLabel: {
    textAlign: 'center',
    fontWeight: 'bold',
  },
}, { index: 1 });
