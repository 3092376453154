import { makeStyles } from '@material-ui/core/styles';
import {
  green, grayBG, cyan, textNoWrap, red,
} from '../../constants';

export default makeStyles({
  TextField: {
    padding: '5px',
    border: `2px ${grayBG} solid`,
    backgroundColor: grayBG,
    '& :-webkit-autofill': {
      boxShadow: `0 0 0px 1000px ${grayBG} inset`,
    },
    '&.error': {
      borderColor: red,
      '& input': {
        color: 'transparent',
        zIndex: -1,
      },
    },
  },
  adornment: {
    margin: '5px',
    width: '20px',
    height: '20px',
  },
  formControl: {
    marginBottom: 20,
    width: '100%',
    '&:first-child': {
      marginTop: 20,
    },
  },
  button: {
    fontWeight: 'bold',
    width: '100%',
    backgroundColor: green,
    color: 'white',
    lineHeight: '30px',
  },
  errorMessage: {
    position: 'absolute',
    top: '15%',
    fontSize: 16,
    left: 35,
    pointerEvents: 'none',
    width: '100%',
    ...textNoWrap,
  },
  mainContainer: {
    padding: '40px 0 0 0',
    overflow: 'hidden',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  logoStyle: {
    width: '100%',
    maxWidth: '200px',
  },
  logoContainer: {
    textAlign: 'center',
    margin: '20px 0',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0',
    padding: '0',
  },
  logoAnimated: {
    width: 'min(400px, 80%)',
  },
  videoContainer: {
    overflow: 'hidden',
    textAlign: 'center',
    padding: 10,
  },
  appInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  appInfoButton: {
    color: cyan,
    position: 'relative',
    '&::before': {
      content: '""',
      position: 'absolute',
      height: '2px',
      width: '5px',
      left: '-10px',
      backgroundColor: cyan,
    },
  },
  siteLinkWrapper: {
    textAlign: 'center',
  },
}, { index: 1 });
