import { Box, Button, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { getTranslateFunction, hideValue } from 'helpers';
import { IOnChange, SosRoute, User } from 'interfaces';

import SharedModal from 'sharedComponents/GlobalModal/GlobalModal';
import sosIcon from 'images/icons/SOS.svg';
import useStyles from '../styles';
import { useLocalStorage } from 'services/customHooks';
import SosFields from './SosFields';
import InfoToggleModal from 'components/InfoToggleModal/InfoToggleModal';
import Preloader from 'components/Preloader/Preloader';

type Props = {
  onChange: (a: IOnChange) => void;
  sosRoute: SosRoute;
  user: User;
  menuSecurityTop: string;
  menuSecurityBottom: string;
};

const SecurityComponent: React.FC<Props> = ({
  sosRoute,
  user,
  onChange,
  menuSecurityTop,
  menuSecurityBottom,
}) => {
  const classes = useStyles();
  // TODO create local storage keys file
  // TODO use database instead of local storage (user-settings)
  const [isSecurityPageDialogClosed, setIsSecurityPageDialogClosed] = useLocalStorage('isSecurityPageDialogClosed', false);
  const t = getTranslateFunction();
  const [ isSosLoading, setIsSosLoading ] = useState(false);
  const [ isAddUserDialogOpen, setIsAddUserDialogOpen ] = useState(false);

  const handleSave = (data: { sosRoute: SosRoute }) => {
    onChange({
      action: 'setSosRoute',
      data,
    });
  };

  const messageExample = useMemo(() => {
    if (!user) return null;

    const {
      city,
      firstName,
      lastName,
      phone,
      placeOfResidence,
    } = user;

    const hiddenPhone = hideValue(phone, 4);

    return ['SOS', `${firstName} ${lastName}`, hiddenPhone, placeOfResidence, city]
      .filter(Boolean)
      .join(' / ');
  }, [user]);

  const handleSos = useCallback(() => {
    setIsSosLoading(true);

    setTimeout(() => {
      setIsSosLoading(false);
    }, 12000);
  }, []);

  return (
    <>
    <Box className={classes.page}>
      {/**
      <Typography className={classes.title}>
        {menuSecurityTop}
      </Typography>
        <Typography className={classes.warningTitle}>
          {menuSecurityBottom}
        </Typography>
      */}

      <Box className={classes.contentWrapper}>
        <br />

        <InfoToggleModal description={t('securityPage.safetyText')} btnLabel='profilePage.infoToggle.btn' isCollapsedTextColorBase />
        {/** TODO use style instead of BR */}
        <br />
        <br />
        <InfoToggleModal description={t('securityPage.safetyWarningText')} btnLabel='profilePage.infoToggle.btn' isButtonColorError />

        <br />
        <br />

        <SosFields onSave={handleSave} sosRoute={sosRoute} />
      </Box>

      <Box className={classes.sosButtonWrapper}>
        {isSosLoading && (<Preloader />)}

        <Typography className={classes.sosExample} color='error'>
          {messageExample}
        </Typography>

        <Button color='primary' className={classes.sosButton} onClick={handleSos}>
          <span className={classes.sosLabel}>
            <img src={sosIcon} alt={t('securityPage.sosImageAlt')} className={classes.sosIcon} />
            <span className={classes.sosText}>{t('securityPage.sosButton')}</span>
          </span>
        </Button>
      </Box>
    </Box>

    <SharedModal
      isOpen={!isSecurityPageDialogClosed}
      confirm={() => setIsSecurityPageDialogClosed(true)}
      title={t('securityPage.safetyTitle')}
      content={t('securityPage.safetyText')}
      buttonText={t('ClientOrderPage.Ok')}
    />
  </>

  );
};

export default SecurityComponent;
