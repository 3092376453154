import { Box, Typography, Button } from '@material-ui/core';
import classNames from 'classnames';
import Timer from 'components/Timer';
import VendorInfoItem from 'components/VendorInfoComponent/VendorInfoItem';
import BuyThingsPreorderListItem from 'pages/PreorderOffersPage/components/BuyThingsPreorderListItem/BuyThingsPreorderListItem';
import VendorMap from 'pages/VendorOffers/VendorMap';
import React, { useMemo } from 'react';
import {
  IOnChange,
  IOrder,
  RatingType,
  User,
  Vendor,
} from 'interfaces';
import { getTranslateFunction } from 'helpers';
import Icons from 'components/Icons';
import useWindowSize from 'services/useWindowSize';
import {
  cyan,
  ORDER_STATUSES,
  clientSecondStep,
  clientFirstStep,
} from '../../../constants';
import useStyles from '../ClientOrderPageStyle';
import OrderMap from './OrderMap';
import OrderModals from './OrderModals';

const {
  PhoneTsx,
  CommentTsx,
} = Icons;

interface Props {
  onChange: (a: IOnChange) => void;
  ratingModal: boolean;
  isSingleTypeOrder: boolean;
  order: IOrder;
  vendorUser: User;
  vendorComments: any[];
  ratingAvg: number;
  vendorRating: RatingType | undefined;
  vendor: Vendor | null;
  distance: number | null;
  vendorLocation: [number, number] | null;
  status: number;
  modalOrderInfo: boolean;
  modalVendorInfo: boolean;
  openMap: boolean;
  waitingLabel: string;
  cancelModalOrder: boolean;
}

const ClientOrderComponent: React.FC<Props> = ({
  onChange,
  ratingModal,
  isSingleTypeOrder,
  order,
  vendorUser,
  vendorComments,
  ratingAvg,
  vendorRating,
  vendor,
  distance,
  vendorLocation,
  status,
  modalOrderInfo,
  modalVendorInfo,
  waitingLabel,
  openMap,
  cancelModalOrder,
}: Props) => {
  const t = getTranslateFunction();
  const { height } = useWindowSize();
  const classes = useStyles(height);

  const showPhoneBtn = useMemo(() => (
    isSingleTypeOrder ? order.showPhone : true
  ), [order]);

  const showChatBtn = useMemo(() => (
    isSingleTypeOrder ? order.withChat : true
  ), [order]);

  const handleChange = (action: string, data?: any) => {
    onChange({
      action,
      data,
    });
  };

  const finishTime = useMemo(() => {
    const dateNow = Math.floor((new Date(order?.createdAt).getTime()) / 1000);
    const orderWaitTime = Number(order?.time) * 60;

    return dateNow + orderWaitTime;
  }, [order]);

  const cancelSuggestions = useMemo(() => (
    status === ORDER_STATUSES.EXITING
      ? clientSecondStep
      : clientFirstStep
  ), [status, ORDER_STATUSES]);

  if (!(vendorUser && vendor)) return null;

  return (
    (
      <Box className={classNames(classes.clientOrderPage, { [classes.openMap]: openMap })}>
        {openMap && (
          <OrderMap
            order={order}
            vendorLocation={vendorLocation}
            onChange={handleChange}
          />
        )}

        {!ratingModal && (
          <Box className={classes.clientOrderContainer}>
            {!isSingleTypeOrder && (
              <Box className={classes.mapContainer}>
                <VendorMap locate={vendorLocation} items={[{ ...order }]} />
              </Box>
            )}
            <Box className={classes.orderInfo}>
              <Typography>{`${vendorUser.firstName} ${t('ClientOrderPage.takeYourOrder')}`}</Typography>

              <Box onClick={() => handleChange('handleOrderInfo')}>
                <Typography className={classes.orderInfoBtn}>
                  {t('ClientOrderPage.OrderInfo')}
                </Typography>
              </Box>

              {Boolean(vendorComments.length) && (
                <Box onClick={() => handleChange('handleVendorInfo')} className={classes.orderInfoBtn}>{t('ClientOrderPage.VendorInfo')}</Box>
              )}
            </Box>
            {isSingleTypeOrder ? (
              <BuyThingsPreorderListItem
                user={vendorUser}
                order={{
                  ...order,
                  time: order.arrivalDateTime,
                }}
                ratingAvg={ratingAvg}
                userRating={vendorRating}
                commentsLength={vendorComments.length}
              />
            )
              : (
                <VendorInfoItem
                  t={t}
                  vendor={vendor}
                  vendorUser={vendorUser}
                  distance={Number(distance)}
                  order={order}
                  ratingAvg={ratingAvg}
                  userRating={vendorRating}
                  commentsLength={vendorComments.length}
                />
              )}
            <Box className={classes.orderContainer}>
              <Box className={classes.infoContainer}>
                <Box className={classes.timer}>{!isSingleTypeOrder && (
                  <Timer
                    finishTime={finishTime}
                    onFinish={() => handleChange('handleOpenDriverOnRoad')}
                  />
                )}
                </Box>
                <Box>
                  {showPhoneBtn && (
                    <Button className={`${classes.iconBtn} phone`}>
                      <a href={`tel:${vendorUser.phone}`}>
                        <PhoneTsx color={cyan} />
                      </a>
                    </Button>
                  )}
                  {showChatBtn && (
                    <Button className={classes.iconBtn} onClick={() => handleChange('handleChatToVendor')}>
                      <CommentTsx color={cyan} />
                    </Button>
                  )}
                </Box>
                <Button className={`${classes.clientBtn} red`} onClick={() => handleChange('handleChangeDeclineModal')}>
                  {t('ClientOrderPage.CancelOrder')}
                </Button>
              </Box>
              {vendorLocation && (
                <Box className={classes.btnContainer}>
                  <Button
                    className={classes.clientBtn}
                    color="primary"
                    variant="contained"
                    disabled={!vendorLocation.length}
                    onClick={() => handleChange('handleMap')}
                  >
                    {t('ClientOrderPage.LookAtMap')}
                  </Button>
                </Box>
              )}
              {status === ORDER_STATUSES.DEFAULT && (
                <Typography className={classes.statusLabel} variant="h6">{`${vendorUser.firstName} ${waitingLabel}`}</Typography>
              )}
              {status === ORDER_STATUSES.EXITING && (
                <>
                  <Typography className={classes.statusLabel} variant="h6">{`${vendorUser.firstName} ${t('ClientOrderPage.notification.DriverInPlace')}`}</Typography>

                  <Button
                    className={classNames([classes.clientBtn, 'sos'])}
                    onClick={() => handleChange('handleSendSaveOurSouls')}
                  >
                    {t('ClientOrderPage.toFriendInfo')}
                  </Button>
                </>
              )}
            </Box>
          </Box>
        )}
        <OrderModals
          ratingModal={ratingModal}
          order={order}
          vendor={vendor}
          vendorRating={vendorRating}
          vendorComments={vendorComments}
          vendorUser={vendorUser}
          ratingAvg={ratingAvg}
          onChange={onChange}
          handleChange={handleChange}
          modalOrderInfo={modalOrderInfo}
          modalVendorInfo={modalVendorInfo}
          cancelModalOrder={cancelModalOrder}
          cancelSuggestions={cancelSuggestions}
        />
      </Box>
    )
  );
};

export default ClientOrderComponent;
