import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import Preloader from 'components/Preloader/Preloader';
import { IOnChange, Vendor } from 'interfaces';
import { useBrands } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import { useAuth } from 'services/auth';
import { getVendorsVerification, updateVendorVerificationFields } from 'services/commonService';
import { booleanFromString } from 'helpers';
import { onSubmit } from '../helpers';
import UpdateVendorPage from '../UpdateVendorPage';

export const FIELDS_TO_VERIFY_VENDOR = [
  'vehicleNumber',
  'vehicleBrand',
  'vehicleModel',
  'vehicleYear',
  'vehicleType',
  'vehicleDoorsCount',
  'vehicleSeatsCount',
  'vehicleColor',
  'vehiclePhotoFront',
  'vehiclePhotoSideLeft',
  'technicalPassportFront',
  'technicalPassportBack',
  'driverLicenseFront',
  'driverLicenseBack',
];

export const getInitVerification = (fieldsToVerify: any, verificationData: any) => {
  return fieldsToVerify.reduce((acc: any, curr: any) => {
    return {
      ...acc,
      [curr]: booleanFromString(verificationData[curr]),
    };
  }, {});
};

interface Location {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  vendor: Vendor;
}

export interface VendorVerificationFields {
  driverLicenseBack: string;
  driverLicenseFront: string;
  technicalPassportBack: string;
  technicalPassportFront: string;
  vehicleBrand: string;
  vehicleColor: string;
  vehicleDoorsCount: string;
  vehicleModel: string;
  vehicleNumber: string;
  vehiclePhotoFront: string;
  vehiclePhotoSideLeft: string;
  vehicleSeatsCount: string;
  vehicleType: string;
  vehicleYear: string;
}

export type VendorVerificationFieldKeys = keyof VendorVerificationFields;

export interface VendorVerification {
  vendorId: number;
  data: VendorVerificationFields;
}

const UpdateVendorPageContainer: React.FC = () => {
  const location = useLocation<Location>();
  const { vendor } = location.state;
  const history = useHistory();
  const { user, setIsLoading, setVendors } = useAuth();

  const [vendorVerificationFields, setVendorVerificationFields] = useState<VendorVerification[]>([]);
  const { brands, getModels, models } = useBrands();

  const handleSubmit = useCallback(async (data: Vendor | any) => {
    setIsLoading(true);
    try {
      const response = await onSubmit(
        {
          ...data,
          createdVehicle: true,
        },
        vendor,
        user,
      );

      setVendors((prev) => prev.map((item) => (item.id === response.id ? response : item)));
      history.push('/vendors-list');
    } catch (error) {
      const e = error as Error;
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleValidationUpdate = (fieldsToUpdate: []) => {
    try {
      updateVendorVerificationFields(vendor.id, fieldsToUpdate);
    } catch (error) {
      const e = error as Error;
      toast.error(e.message);
    }
  };

  const handleChange = useCallback(({ action, data }: IOnChange) => {
    switch (action) {
      case 'updateVendorSubmit':
        handleSubmit(data);
        break;
      case 'updateVendorValidation':
        handleValidationUpdate(data);
        break;
      case 'getModels':
        getModels(data);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    const getUserVerificationData = async () => {
      try {
        setIsLoading(true);

        if (!user) {
          throw new Error('user.is.not.defined');
        }

        const verificationData = await getVendorsVerification(user.id);
        const initVerification = verificationData.map((data) => {
          const vendorData = getInitVerification(FIELDS_TO_VERIFY_VENDOR, data);
          return { vendorId: data.vendorId, data: vendorData };
        });

        setVendorVerificationFields(initVerification);
      } catch (error) {
        const e = error as Error;
        toast.error(e.message);
      }
      setIsLoading(false);
    };

    getUserVerificationData();
  }, []);

  const currentVendorVerification = vendorVerificationFields.find((data) => {
    return data.vendorId === vendor.id;
  });

  const isDataReady = brands && models && vendor && currentVendorVerification?.data;

  return (
    <>
      {isDataReady ? (
        <UpdateVendorPage
          brands={brands}
          models={models}
          vendor={vendor}
          onChange={handleChange}
          verification={currentVendorVerification.data}
        />
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default UpdateVendorPageContainer;
