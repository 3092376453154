import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import axios from 'axios';
import { Box } from '@material-ui/core';
import PreorderOffersContainer from 'pages/PreorderOffersPage/containers/PreorderOffersContainer';
import { useForm } from 'react-hook-form';
import SelectLocation from 'components/SelectLocation/SelectLocation';
import PreorderPaymentModal, { TPreorderModal } from 'sharedComponents/PreorderPaymentModal';
import { useHistory } from 'react-router';
import PreorderMap from 'components/PreorderMap/index';
import { LatLngTuple } from 'leaflet';
import {
  configureOSRMLink, getBase64FromFile, getCurrencyByCountry, getTranslateFunction,
} from 'helpers';
import { ModelItem } from 'components/ConstructorForm/items/CarCombobox/useBrands';
import {
  IOnChange,
  RatingArrayItem,
  User,
  Vendor,
  Preorder,
  PreorderService,
  PreorderFormData,
  IPoint,
  TBalance,
  IDistance,
  IComment,
} from 'interfaces';
import icons from 'components/Icons';
import useWindowSize from 'services/useWindowSize';
import { filterDataWithoutNull, formatOrderClass, parseQueryString } from 'utils/formatters';
import PreorderPageForm, { FieldValues } from './PreorderPageForm';
import { usePreorderRoute } from '../usePreorderRoute';
import useStyles from '../PreorderPageStyle';
import { SINGLE_TYPES, TYPES_WITH_POINTS } from '../../../constants';

const { securityPaymentSvg } = icons;

type ArrayVerificationNameInputValue = 'preorderName' |
'vehicleBrandOfBuy' |
'vehicleModelOfBuy' |
'releaseDateFromOfBuy' |
'releaseDateToOfBuy' |
'vehicleColorOfBuy' |
'transmissionOfBuy';

const arrayVerificationNameInputValue: ArrayVerificationNameInputValue[] = [
  'vehicleBrandOfBuy',
  'vehicleModelOfBuy',
  'releaseDateFromOfBuy',
  'releaseDateToOfBuy',
  'vehicleColorOfBuy',
  'transmissionOfBuy',
];

interface Props {
  brands: string[];
  models: ModelItem[];
  onChange: (a: IOnChange) => void;
  user: User;
  vendors: Vendor[];
  vendorsRating: RatingArrayItem[];
  vendorsComments: IComment[];
  preorder: Preorder | null;
  currentType: string;
  users: User[];
  offers: PreorderService[];
  balance: TBalance;
  insufficientModal: TPreorderModal | null;
  onCloseInsufficientModal: () => void;
}

const defaultValues = {
  price: '',
  comment: '',
  hasBabyChair: false,
  together: 'individual',
  moreThan4: false,
  less10min: false,
  carClass: '',
  paymentType: 'cash',
  passengers: 1,
  tripTime: 'now',
  baggage: false,
  tripRequirements: '',
  tripTimeDate: '',
  detailedDescription: '',
  preorderType: '',
  buyerCity: '',
  findInAllCities: false,
  providedCities: [],
  showPhone: false,
  withChat: false,
  preorderName: '',
  preorderPhotos: [],
  transmissionOfBuy: '',
  vehicleColorOfBuy: '',
  releaseDateToOfBuy: '',
  releaseDateFromOfBuy: '',
  vehicleModelOfBuy: '',
  vehicleBrandOfBuy: '',
};

const CreateRoutePage: React.FC<Props> = ({
  balance,
  user,
  users,
  offers,
  vendors,
  vendorsRating,
  vendorsComments,
  onChange,
  preorder,
  currentType,
  brands,
  models,
  insufficientModal,
  onCloseInsufficientModal,
}) => {
  const { height } = useWindowSize();
  const t = getTranslateFunction();
  const [selectOnMap, setSelectOnMap] = useState(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [distance, setDistance] = useState<IDistance | null>(null);
  const classes = useStyles({ selectOnMap, height });
  const history = useHistory();
  const searchParams = parseQueryString(history.location.search);
  const [[currentSubType, currentSubTypeVal]] = Object.entries(searchParams);

  const {
    points,
    addPoint,
    onChange: onChangeRoute,
    setActivePoint,
    activePoint,
    removePoint,
    setPoints,
    handleBack,
  } = usePreorderRoute();

  useEffect(() => {
    setActivePoint(searchParams.pointId ? Number(searchParams.pointId) : null);
    if (!searchParams.pointId) {
      setSelectOnMap(false);
    }
  }, [searchParams?.pointId]);

  const [route, setRoute] = useState<LatLngTuple[]>([]);
  const [isMoving, setIsMoving] = useState(false);
  const isSingleTypePreorder = SINGLE_TYPES.some((type: string) => type === currentType);
  const withPointsType = TYPES_WITH_POINTS.some((type: string) => type === currentType);

  const currency = useMemo(() => getCurrencyByCountry(user?.country ?? ''), [user]);

  const {
    handleSubmit,
    getValues,
    control,
    reset,
    errors,
    clearErrors,
    register,
    watch,
    setValue,
    setError,
  } = useForm({ defaultValues: { ...defaultValues, buyerCity: user?.city } });

  const findInAllCitiesValue = watch('findInAllCities');
  const providedCitiesValue = watch('providedCities');

  useEffect(() => {
    if (findInAllCitiesValue || providedCitiesValue) {
      clearErrors('findInAllCities');
      clearErrors('providedCities');
    }
  }, [findInAllCitiesValue, providedCitiesValue]);

  useEffect(() => {
    const allFilled = withPointsType && points.every(
      (point: IPoint) => 'lat' in point && 'lon' in point,
    );
    if (allFilled) {
      const query = points.map((point) => `${point.lon},${point.lat}`).join(';');
      axios
        .get(configureOSRMLink(query))
        .then((res) => {
          setDistance(res.data.routes[0].distance);
          setRoute(res.data.routes[0].geometry.coordinates);
          setDisabled(false);
        });
    }
  }, [activePoint, points]);

  useEffect(() => {
    if (SINGLE_TYPES.some((type: string) => {
      const isInclude = history.location.pathname.includes(type);

      return isInclude;
    })) {
      setDisabled(false);
    }
  }, [history, user]);

  const updatePreorder = (newPreorder: Preorder) => {
    onChange({
      action: 'updatePreorder',
      data: newPreorder,
    });
  };

  const editPreorder = (newPreorder: any) => {
    onChange({
      action: 'editPreorder',
      data: newPreorder,
    });

    setPoints(newPreorder.dots);
    reset(newPreorder);
  };

  const handleConfirm = async (data: PreorderFormData) => {
    const isSingleType = [...SINGLE_TYPES, 'helpOnRoad'].includes(currentType);

    if (!user) return;

    if (isSingleTypePreorder) {
      const isSelectedCities = data.findInAllCities || data?.providedCities?.length;
      const errorVerificationInputValue = arrayVerificationNameInputValue.filter((item) => {
        const verificationItemInData = Object.keys(data).includes(item);
        return (isSingleTypePreorder && verificationItemInData && !data[item]);
      });

      if (errorVerificationInputValue.length || !isSelectedCities) {
        setError('findInAllCities', { message: 'preorderPage.findInAllCities.required' });
        setError('providedCities', { message: 'preorderPage.providedCities.required' });
        return;
      }
    }

    const { preorderPhotos, price, ...formInfo } = data;
    setActivePoint(null);
    setSelectOnMap(false);
    const subType = isSingleType ? 'inCity' : currentSubType;

    const formattedValue = currentSubTypeVal.replace(/\[|\]/g, '');
    const formattedOrderClass = formatOrderClass(currentSubType, formattedValue);
    const multiTypePreorder = (currentType === 'alongTheWay' && data.carClass) || formattedOrderClass;

    const resultOrderClass = !withPointsType ? currentSubType : multiTypePreorder;

    let base64Files: string[] | undefined = [];
    if (preorderPhotos) {
      const result = (await Promise.all(Object.values(preorderPhotos).filter(Boolean)
        .map(([file]: [File]) => file && getBase64FromFile(file)))).filter(Boolean);

      base64Files = result.length ? result : undefined;
    }

    const normalizedPoints = points.filter(({ value }) => value.length);

    const newPreorder = {
      type: currentType,
      customerId: user?.id,
      path: route,
      dots: normalizedPoints,
      distance,
      subType,
      currency,
      preorderPhotos: base64Files,
      country: user?.country,
      price: Number(price),
      orderClass: resultOrderClass,
      [currentSubType]: formattedValue,
      ...formInfo,
    };
    const nonNullableData = filterDataWithoutNull(newPreorder);

    if (!base64Files?.length) {
      delete nonNullableData.preorderPhotos;
    }

    onChange({
      action: 'createPreorder',
      data: nonNullableData,
    });
  };

  const handleRemovePoint = useCallback((index: number) => {
    setSelectOnMap(false);
    removePoint(index);
  }, []);

  const pointControlsList = useMemo(() => {
    if (!withPointsType) return [];

    return points.map((point: IPoint, i: number) => {
      const isShouldRender = typeof activePoint !== 'number' || i === activePoint;
      if (!isShouldRender) return null;
      const showRemovePointBtn = points.length > 2 && !isMoving && activePoint === i;

      const label = i ? 'toLabel' : 'fromLabel';
      return (
        <SelectLocation
          key={point.id}
          index={i}
          point={point}
          placeholder={t(`preorderPage.${label}`)}
          dataName={i ? 'toLabel' : 'from'}
          handleBack={handleBack}
          onFocus={() => {
            setSelectOnMap(false);
            setActivePoint(i);
          }}
          removePoint={showRemovePointBtn && handleRemovePoint}
          setActivePoint={setActivePoint}
          isActive={activePoint === i}
          onChange={onChangeRoute}
          onMapSelect={setSelectOnMap}
        />
      );
    }).filter(Boolean);
  }, [withPointsType, activePoint, points, isMoving]);

  const validateDate = (value: string, name: string) => {
    const date = new Date(value);
    if (Number.isNaN(date.getTime())) {
      setError(name, {
        type: 'manual',
        message: t('invalid.date.format'),
      });
      return false;
    }
    setError(name, {
      type: '',
      message: '',
    });
    return true;
  };

  const createPreorderToDTO = (data: FieldValues) => {
    const result = data;
    const newTripTime = result.tripTime === 'now' ? result.tripTime : result.tripTimeDate;
    delete result.tripTimeDate;

    return {
      ...result,
      tripTime: newTripTime,
    };
  };

  const onSubmit = (data: FieldValues) => {
    if (data.tripTimeDate) {
      const isValidDate = validateDate(data.tripTimeDate, 'tripTimeDate');
      if (!isValidDate) {
        return;
      }
    }

    const formattedData = createPreorderToDTO(data);
    handleConfirm(formattedData);
  };

  const showMap = selectOnMap && typeof activePoint === 'number';

  return (
    <Box className={classes.preorderContainer}>
      <Box className={classes.mainContainer}>
        {!preorder ? (
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={classes.preorderForm}
          >
            <PreorderPageForm
              onChange={onChange}
              brands={brands}
              models={models}
              user={user}
              selectOnMap={selectOnMap}
              control={control}
              errors={errors}
              pointControlsList={pointControlsList}
              activePoint={activePoint}
              addPoint={addPoint}
              disabled={disabled}
              setValue={setValue}
              getValues={getValues}
              clearErrors={clearErrors}
              register={register}
              location={history.location}
            />
          </form>
        ) : (
          <PreorderOffersContainer
            preorder={preorder}
            users={users}
            offers={offers}
            vendors={vendors}
            vendorsRating={vendorsRating}
            vendorsComments={vendorsComments}
            onChange={onChange}
            updatePreorder={updatePreorder}
            editPreorder={editPreorder}
          />
        )}

        {showMap && (
        <PreorderMap
          centeredPinVisible={selectOnMap}
          currentSubType={currentSubType}
          points={points}
          activePoint={activePoint}
          onMapSelect={setSelectOnMap}
          setIsMoving={setIsMoving}
          onSubmit={(pointValue) => {
            onChangeRoute(Number(activePoint), pointValue);
          }}
          onCancel={() => {
            setSelectOnMap(false);
            history.goBack();
          }}
        />
        )}
      </Box>

      {insufficientModal && (
        <PreorderPaymentModal
          onClose={onCloseInsufficientModal}
          currency={currency}
          balance={balance[currency]}
          value={insufficientModal}
          icon={securityPaymentSvg}
        />
      )}
    </Box>
  );
};

export default CreateRoutePage;
